import React from 'react';
import { isCommunityManager } from 'utils/roles';

export const NEWS_TAB = 'news';
export const SOCIAL_TAB = 'social';
export const ALL_POSTS_TAB = 'allPosts';

// TEMP FEED - remove once social tabs enabled
export const CASINOVERSE_FEED = 'casinoverseFeed';
// Admin Only
export const LEGACY_FEED = 'legacyFeed';

export function getUsersTooltipMessage(objects, getName) {
  if (!objects || !objects.length) {
    return null;
  }

  let length = 10;

  if (objects.length < length) {
    length = objects.length;
  }

  let elements = [];

  for (let i = 0; i < length; i++) {
    elements.push(<div key={'a' + i}>{getName(objects[i])}</div>);
  }

  if (objects.length > 10) {
    elements.push(
      <div key={'a+'}>{`...And ${objects.length - 10} more.`}</div>
    );
  }

  return elements.length === 1 ? elements[0] : elements;
}

export function canEditPostOrComment(item) {
  //Check if current user owns the post/comment.
  return item && item.byMe;
}

export function canDeletePostOrComment(user, item) {
  // If admin, they can always delete.
  if (user && isCommunityManager(user)) {
    return true;
  }

  // Check if current user owns the post/comment.
  return item && item.byMe;
}
