import { getLocalData, setLocalData } from 'utils/localStorage';
import { CASINOVERSE_FEED, LEGACY_FEED } from './wall';

const getLocalStorageCasinoverse = () => !!getLocalData('casinoverse', true);
const setLocalStorageCasinoverse = s => setLocalData('casinoverse', !!s);

export const getCasinoverse = () => getLocalStorageCasinoverse();

export const getSavedTab = () => {
  const fallbackTab = getCasinoverse() ? CASINOVERSE_FEED : LEGACY_FEED;
  return getLocalData('selectedTab', fallbackTab);
};

const saveSelectedTab = tab => setLocalData('selectedTab', tab);

let reloadTriggered = false;

export const turnOnCasinoverse = selectedTab => {
  if (!reloadTriggered && !getCasinoverse()) {
    reloadTriggered = true;

    if (selectedTab === CASINOVERSE_FEED) {
      saveSelectedTab(selectedTab);
    }

    setLocalStorageCasinoverse(true);
    window.location.reload();
  }
};

export const turnOffCasinoverse = () => {
  if (!reloadTriggered && getCasinoverse()) {
    reloadTriggered = true;

    saveSelectedTab(LEGACY_FEED);
    setLocalStorageCasinoverse(false);
    window.location.reload();
  }
};
